<template>
    <section class="section">
        <v-sheet id="scrolling-techniques-7" class="overflow-y-auto" max-height="90vh">
            <v-container class="mt-4">
                <TransportFilters
                    @setTransportQuery="getTransportsList"
                    v-model="isFiltersActive"
                    :transports="transportListTable">
                </TransportFilters>

                <div id="transport-list" class="mt-8">
                    <div class="d-flex">
                        <!-- search -->
                        <v-text-field
                            expanded
                            v-model="search"
                            @change="searchTransports()"
                            :placeholder="$t('Search')"
                            outlined
                            hide-details
                            dense
                            :prepend-inner-icon="icons.mdiMagnify"
                            class="user-search me-3 mb-4">
                        </v-text-field>

                        <v-btn @click.stop="isFiltersActive = !isFiltersActive" outlined color="grey-light">
                            <v-icon color="grey">
                                {{ icons.mdiFilterVariant }}
                            </v-icon>
                        </v-btn>
                    </div>

                    <v-data-table
                        show-select
                        calculate-widths
                        :items-per-page="25"
                        :footer-props="{
                            'items-per-page-options': [25],
                            'items-per-page-text': $t('RowsPerPage'),
                        }"
                        :header-props="{ 'sort-by-text': $t('SortBy') }"
                        :headers="transportTableColumns"
                        :items="transportListTable"
                        :server-items-length="totalTransportListTable"
                        :loading="isLoading"
                        v-model="selectedTransports"
                        :options.sync="options"
                        @update:page="paginationHandle"
                        height="500"
                        fixed-header>
                        <template #[`item.name`]="{ item }">
                            <div class="extend-column d-flex flex-column ms-3">
                                <router-link
                                    style="max-width: 12rem"
                                    :to="{ name: 'TransportProfile', params: { id: item.id } }"
                                    class="text--primary font-weight-semibold text-truncate cursor-pointer text-decoration-none">
                                    {{ item.name }}
                                </router-link>
                            </div>
                        </template>
                        <!-- type -->
                        <template #[`item.type`]="{ item }">
                            <span class="font-weight-medium text--primary text-center">
                                {{ transportTypes[item.type] }}
                            </span>
                        </template>
                        <!-- total capacity -->
                        <template #[`item.totalCapacity`]="{ item }">
                            <span class="font-weight-medium text--primary text-center">
                                {{ item.totalCapacity }}
                            </span>
                        </template>
                        <!-- handicapped capacity -->
                        <template #[`item.handicappedCapacity`]="{ item }">
                            <span class="font-weight-medium text--primary text-center">
                                {{ item.handicappedCapacity }}
                            </span>
                        </template>
                        <!-- standing capacity -->
                        <template #[`item.standingCapacity`]="{ item }">
                            <span class="font-weight-medium text--primary text-center">
                                {{ item.standingCapacity }}
                            </span>
                        </template>
                        <!-- free seats -->
                        <template #[`item.freeSeats`]="{ item }">
                            <span class="font-weight-medium text--primary text-center">
                                {{ item.freeSeats }}
                            </span>
                        </template>
                        <template #[`item.actions`]="{ item }">
                            <v-btn @click="$router.push({ name: 'TransportProfile', params: { id: item.id } })" icon>
                                <v-icon large>{{ icons.mdiChevronRight }}</v-icon>
                            </v-btn>
                        </template>
                    </v-data-table>
                </div>
            </v-container>
        </v-sheet>

        <v-app-bar height="80" absolute color="white" elevate-on-scroll scroll-target="#scrolling-techniques-7">
            <v-container class="d-flex">
                <v-toolbar-title>
                    <p class="d-none d-md-flex d-lg-flex d-xl-flex text-h5 font-weight-semibold black--text mt-1">
                        {{ $t('TransportsList') }}
                    </p>
                    <p class="text-h6 font-weight-semibold black--text mt-1 d-flex d-xs-flex d-sm-flex d-md-none">
                        {{ $t('TransportsList') }}
                    </p>
                </v-toolbar-title>

                <v-spacer></v-spacer>

                <v-btn
                    @click="$router.push({ name: 'TransportProfile', params: { id: 'add' } })"
                    color="primary"
                    class="mx-3">
                    <v-icon class="mr-2 d-none d-md-flex d-lg-flex d-xl-flex" color="white">
                        {{ icons.mdiPlus }}
                    </v-icon>
                    <v-icon class="d-flex d-xs-flex d-sm-flex d-md-none" color="white">
                        {{ icons.mdiPlus }}
                    </v-icon>
                    <span class="d-none d-md-flex d-lg-flex d-xl-flex">{{ $t('CreateTransport') }}</span>
                </v-btn>
                <v-dialog v-if="selectedTransports.length > 0" transition="dialog-top-transition" max-width="600">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn outlined color="error" v-bind="attrs" v-on="on">
                            <v-icon class="mr-2 d-none d-md-flex d-lg-flex d-xl-flex" color="error">
                                {{ icons.mdiTrashCanOutline }}
                            </v-icon>
                            <v-icon class="d-flex d-xs-flex d-sm-flex d-md-none" color="error">
                                {{ icons.mdiTrashCanOutline }}
                            </v-icon>
                            <span class="d-none d-md-flex d-lg-flex d-xl-flex">{{ $t('Buttons.Delete') }}</span>
                        </v-btn>
                    </template>
                    <template v-slot:default="dialog">
                        <v-img class="mx-auto trashcan" width="80" src="@/assets/icons/delete.png"></v-img>

                        <v-card>
                            <v-card-text class="text-center">
                                <p class="title font-weight-semibold black--text mt-12">{{ $t('Alerts.Sure') }}</p>
                            </v-card-text>
                            <v-card-text class="text-center">
                                <p v-if="selectedTransports.length === 1" class="body-2">
                                    {{ $t('Alerts.DeleteTransport') }}
                                    <strong class="black--text">{{
                                        selectedTransports.map((entry) => entry.name).join(', ')
                                    }}</strong>
                                    ?<br />
                                    {{ $t('Alerts.Irreversible') }}
                                </p>
                                <p v-else class="body-2">
                                    {{ $t('Alerts.SureDeleteTransports') }}
                                    <strong class="black--text">{{
                                        selectedTransports.map((entry) => entry.name).join(', ')
                                    }}</strong>
                                    ?<br />
                                    {{ $t('Alerts.Irreversible') }}
                                </p>
                            </v-card-text>

                            <v-card-actions class="justify-end mt-4">
                                <v-btn @click="deleteSelectedTransports" color="error" class="px-5">{{
                                    $t('Buttons.Delete')
                                }}</v-btn>
                                <v-btn text @click="dialog.value = false">{{ $t('Buttons.Cancel') }}</v-btn>
                            </v-card-actions>
                        </v-card>
                    </template>
                </v-dialog>
            </v-container>
        </v-app-bar>
    </section>
</template>

<script>
    import {
        mdiAccountOutline,
        mdiChevronRight,
        mdiDeleteOutline,
        mdiDotsVertical,
        mdiExportVariant,
        mdiFileDocumentOutline,
        mdiFilterVariant,
        mdiMagnify,
        mdiPlus,
        mdiSquareEditOutline,
        mdiTrashCanOutline,
    } from '@mdi/js';
    import { ref } from '@vue/composition-api';

    import useTransportsList from './useTransportsList';
    import TransportFilters from '../transports-filters/TransportsFilters.vue';
    export default {
        components: {
            TransportFilters,
        },
        setup() {
            const {
                transportListTable,
                transportTableColumns,
                totalTransportListTable,
                searchQuery,
                options,
                loading,
                fetchTransportList,
            } = useTransportsList();

            const isFiltersActive = ref(false);

            return {
                searchQuery,
                options,
                loading,
                transportTableColumns,
                transportListTable,
                totalTransportListTable,
                isFiltersActive,
                fetchTransportList,

                // icons
                icons: {
                    mdiSquareEditOutline,
                    mdiFileDocumentOutline,
                    mdiDotsVertical,
                    mdiDeleteOutline,
                    mdiPlus,
                    mdiExportVariant,
                    mdiAccountOutline,
                    mdiMagnify,
                    mdiChevronRight,
                    mdiFilterVariant,
                    mdiTrashCanOutline,
                },
            };
        },

        created() {
            this.isLoading = true;
            this.$store.commit('app-transport/resetTransportsQuery');
            this.query = this.$store.getters['app-transport/getTransportsQuery'];
            this.fetchTransportList();
            setTimeout(() => {
                this.isLoading = false;
            }, 1000);
        },

        data() {
            return {
                search: null,
                selectedTransports: [],
                isFiltersActive: false,
                isLoading: false,
                isSuccess: false,
                isError: false,
                query: {},
                transportTypes: {
                    roadway: 'Autocarro',
                    railway: 'Comboio',
                    subway: 'Metro',
                },
                options: {
                    page: 1,
                },
            };
        },

        methods: {
            getTransportsList() {
                this.isLoading = true;
                this.fetchTransportList();

                setTimeout(() => {
                    this.isLoading = false;
                }, 1000);
            },
            searchTransports() {
                this.isLoading = true;
                this.$store.commit('app-transport/setTransportSearch', this.search);
                this.query = this.$store.getters['app-transport/getTransportsQuery'];
                this.query.pagination.page = 1;
                this.fetchTransportList();

                setTimeout(() => {
                    this.isLoading = false;
                }, 1000);
            },

            deleteSelectedTransports() {
                this.isLoading = true;
                for (let i = 0; i < this.selectedTransports.length; i++) {
                    this.$store
                        .dispatch('app-transport/deleteTransport', this.selectedTransports[i].id)
                        .then((response) => {
                            this.isSuccess = true;
                            this.$store.commit('app-transport/setIsDeleteTransport', true);
                            this.fetchTransportList();
                            setTimeout(() => {
                                this.isLoading = false;
                            }, 1000);
                        })
                        .catch((error) => {
                            this.isError = true;

                            setTimeout(() => {
                                this.isLoading = false;
                            }, 1000);
                        });
                }
                this.selectedTransports = [];
            },

            paginationHandle(e) {
                if (this.options.page > this.query.pagination.page) {
                    this.query.pagination.page = this.options.page;
                    this.$store.commit('app-transport/setTransportsQuery', this.query);
                    this.fetchTransportList();
                }
            },
        },
    };
</script>
